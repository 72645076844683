import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import '../public/static/css/common.css'
import '../public/static/css/reset.css'
import animated from 'animate.css'
Vue.use(animated)
import axios from 'axios'
// 配置请求根路径
// // 本机地址
//axios.defaults.baseURL = 'http://127.0.0.1:8090/api/'

// 挂在到Vue实例，后面可通过this调用
Vue.prototype.$http = axios

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  })

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')