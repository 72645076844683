<template>
  <div class="headerBox">
    <div class="w1200 flex align-center hp100">
      <img
        src="../assets/images/product/selfOrder/logo1.png"
        alt=""
        width="142"
        height="35"
        class="headerlogo"
      />
      <ul class="nav flex align-center hp100" style="color: #000">
        <li class="">
          <router-link to="/index" class="am positionr"
            >首页</router-link
          >
        </li>
        <li class="">
          <a
            href="#"
            class="am positionr productAm"
            :class="researchActive == 1 ? 'router-link-active' : ''"
            >产品</a
          >
          <div>
            
          </div>
          <div class="SubNav">
            <div class="w1100 flex justify-center">
              <div class="SubNavItem">
                <div class="SubNavItem_tit text999">收银系统</div>
                <dl class="SubNavItem_cont">
                  <router-link
                    to="/product/pos/windows"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/windows.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">Windows客户端</span>
                  </router-link>
                  <router-link
                    to="/product/pos/ipad"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/ipad.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">ipad收银系统</span>
                  </router-link>
                  <router-link
                    to="/product/pos/android"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/android.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">Android收银系统</span>
                  </router-link>
                  <router-link
                    to="/product/pos/selfOrder"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/zizhu.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">自主点单</span>
                  </router-link>
                </dl>
              </div>
              <div class="SubNav_line"></div>
              <div class="SubNavItem">
                <div class="SubNavItem_tit text999">门店管理</div>
                <dl class="SubNavItem_cont">
                  <router-link
                    to="/product/pos/cloud"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/yunhoutai.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">云后台</span>
                  </router-link>
                  <router-link
                    to="/product/pos/pcStore"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/PC.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">PC店铺管家</span>
                  </router-link>
                  <router-link
                    to="/"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/ERP.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">ERP进销存</span>
                  </router-link>
                </dl>
              </div>
              <div class="SubNav_line"></div>
              <div class="SubNavItem">
                <div class="SubNavItem_tit text999">微商城&小程序</div>
                <dl class="SubNavItem_cont">
                  <router-link
                    to="/product/pos/onlineClick"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/online.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">线上点单小程序</span>
                  </router-link>
                  <router-link
                    to="/product/pos/scanOrder"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/saoma.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">扫码点单</span>
                  </router-link>
                  <router-link
                    to="/product/pos/cusApplet"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/keyong.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">客用教程小程序</span>
                  </router-link>
                </dl>
              </div>
              <div class="SubNav_line"></div>
              <div class="SubNavItem">
                <div class="SubNavItem_tit text999">创新应用</div>
                <dl class="SubNavItem_cont">
                  <router-link
                    to="/"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/zhinengganying.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">智能感应点菜盘</span>
                  </router-link>
                  <router-link
                    to="/product/pos/faceRecognition"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/renlianshibie.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">人脸识别</span>
                  </router-link>
                  <router-link
                    to="/"
                    class="bm flex align-center"
                  >
                    <img
                      src="../assets/images/header/yitihua.png"
                      alt=""
                      class="menuIcon"
                    />
                    <span class="ml20 text333 fw700">智能一体化结算设备</span>
                  </router-link>
                </dl>
              </div>
            </div>
          </div>
        </li>
        <li>
          <router-link to="/catering" class="am positionr "
            >餐饮解决方案</router-link
          >
        </li>
        <li>
          <router-link to="/casesTwo" class="am positionr "
            >商家案例</router-link
          >
        </li>
        <li>
          <router-link to="/download" class="am positionr "
            >下载中心</router-link
          >
        </li>
      </ul>
      <div class="loginBtn">
        <a href="http://pos.yunlankeji.com/" target="_blank">
          <button>商户登录</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      researchActive: 0,
      width:'',
      isShow: false
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        var that = this;
        if (
          route.path == "/product/pos/windows" ||
          route.path == "/product/pos/ipad" ||
          route.path == "/product/pos/android" ||
          route.path == "/product/pos/selfOrder" ||
          route.path == "/product/pos/cloud" ||
          route.path == "/product/pos/faceRecognition" ||
          route.path == "/product/pos/pcStore" ||
          route.path == "/product/pos/cusApplet" ||
          route.path == "/product/pos/onlineClick" ||
          route.path == "/product/pos/scanOrder"
          
        ) {
          that.researchActive = 1;
        } else {
          that.researchActive = 0;
        }
      },
      immediate: true,
    },
  },
  methods: {

  },
  created() {},
  mounted() {
    var _width = $(window).width();
    this.width = _width
    if (_width > 1024) {
      $(".nav li").hover(
        function () {
          $(this).find(".SubNav").stop(true, true).fadeIn();
        },
        function () {
          $(this).find(".SubNav").stop(true, true).fadeOut();
        }
      );
    } else {

      // $(".nav .productAm").mouseover(function(){
      //   $('.SubNav').css("display","block")
      // })
      // $(".nav .productAm").mouseout(function(){
      //   $('.SubNav').css("display","none")
      // })
    }
  },
};
</script>

<style>
.headerBox {
  position: relative;
}
.loginBtn{
  margin-left: 30px;
}
.loginBtn button{
  width: 90px;
  height: 36px;
  text-align: center;
  background-color: transparent;
  border: 1px solid #ff6727;
  box-sizing: border-box;
  color: #ff6727;
  font-size: 16px;
}
</style>