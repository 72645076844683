import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 路由懒加载
const routes = [
  { path: "/", redirect: "/index" },
  {
    path: "/index",
    component: () =>
      import("@/views/index/Home.vue"),
      meta: {
        title: '店教授'
      }
  },
  {
    path: "/product",
    component: () =>
      import("@/views/product/Product.vue"),
      meta: {
        title: '产品'
      }
  },
  {
    path: "/product/pos/windows",
    component: () =>
      import("@/views/product/pos/Windows.vue"),
      meta: {
        title: 'Windows客户端'
      }
  },
  {
    path: "/product/pos/ipad",
    component: () =>
      import("@/views/product/pos/Ipad.vue"),
      meta: {
        title: 'ipad收银系统'
      }
  },
  {
    path: "/product/pos/android",
    component: () =>
      import("@/views/product/pos/Android.vue"),
      meta: {
        title: 'Android收银系统'
      }
  },
  {
    path: "/product/pos/selfOrder",
    component: () =>
      import("@/views/product/pos/SelfOrder.vue"),
      meta: {
        title: '自主点单'
      }
  },
  {
    path: "/catering",
    component: () =>
      import("@/views/catering/CateringSolutions.vue"),
      meta: {
        title: '餐饮解决方案'
      }
  },
  {
    path: "/cases",
    component: () =>
      import("@/views/cases/BusinessCase.vue"),
      meta: {
        title: '商家案例'
      }
  },
  {
    path: "/download",
    component: () =>
      import("@/views/download/Download.vue"),
      meta: {
        title: '下载中心'
      }
  },
  {
    path: "/product/pos/cloud",
    component: () =>
      import("@/views/product/pos/Cloud.vue"),
      meta: {
        title: '云后台'
      }
  },
  {
    path: "/product/pos/faceRecognition",
    component: () =>
      import("@/views/product/pos/FaceRecognition.vue"),
      meta: {
        title: '人脸识别'
      }
  },
  {
    path: "/product/pos/pcStore",
    component: () =>
      import("@/views/product/pos/PCStore.vue"),
      meta: {
        title: 'PC店铺'
      }
  },
  {
    path: "/product/pos/cusApplet",
    component: () =>
      import("@/views/product/pos/CusApplet.vue"),
      meta: {
        title: '客用教程小程序'
      }
  },
  {
    path: "/product/pos/onlineClick",
    component: () =>
      import("@/views/product/pos/OnlineClick.vue"),
      meta: {
        title: '线上点单'
      }
  },
  {
    path: "/product/pos/scanOrder",
    component: () =>
      import("@/views/product/pos/ScanOrder.vue"),
      meta: {
        title: '扫码点单'
      }
  },
  {
    path: "/casesTwo",
    component: () =>
      import("@/views/cases/BusinessCaseTwo.vue"),
      meta: {
        title: '商家案例'
      }
  },
  {
    path: "/about",
    component: () =>
      import("@/views/about/About.vue"),
      meta: {
        title: '关于我们'
      }
  },
  {
    path: "/contact",
    component: () =>
      import("@/views/contact/Contact.vue"),
      meta: {
        title: '联系我们'
      }
  },
  {
    path: "/cooperation",
    component: () =>
      import("@/views/cooperation/Cooperation.vue"),
      meta: {
        title: '渠道合作'
      }
  },
  {
    path: "/serviceAgreement",
    component: () =>
      import("@/views/serviceAgreement/ServiceAgreement.vue"),
      meta: {
        title: '服务协议'
      }
  },
  {
    path: "/compliance",
    component: () =>
      import("@/views/compliance/Compliance.vue"),
      meta: {
        title: '廉洁合规'
      }
  },
  {
    path: "/businessLience",
    component: () =>
      import("@/views/businessLicense/BusinessLicense.vue"),
      meta: {
        title: '工商营业执照'
      }
  },
  {
    path: "/increment",
    component: () =>
      import("@/views/businessLicense/Increment.vue"),
      meta: {
        title: '增值电信业务经营许可证'
      }
  },
];

const router = new VueRouter({
  routes,
});

export default router;
