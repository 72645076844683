<template>
  <!-- <div class="footerBox">
      <div class="w1200 flex align-center justify-between hp100">
           <div class="footer_left flex align-center">
               <img src="../assets/images/product/selfOrder/logo1.png" alt="" width="240">
               <div class="comInfo">
                   <div>
                       <p class="footer_tit ">联系电话</p>
                       <p>400-066-0526</p>
                   </div>
                   <div class="mt15">
                       <p class="footer_tit">公司官网</p>
                       <p>www.yunlankeji.com</p>
                   </div>
                   <div class="mt15">
                       <p class="footer_tit">公司地址</p>
                       <p>合肥市蜀山区望江西路西湖国际广场B座</p>
                   </div>
               </div>
           </div>
           <div class="footer_right text-white textc">
               <div class="textc">
                  <img src="../assets/images/common/wechat.png" alt="" width="25" class="positionr" style="top:0;display:inline-block">
                  <span class="ml5">微信咨询</span>
               </div>
               <div class="mt5">
                  <img src="../assets/images/common/code.png" alt="" width="140" height="140">
               </div>
               <div class="mt15 textc">安徽云蓝信息科技有限公司</div>
           </div>
      </div>
  </div> -->

  <div class="section footer">
    <div class="footer-content">
      <div class="footer-section footer-section_01">
        <div class="footer-section__title">联系我们</div>
        <div class="footer-section__row">
          <div class="footer-section__row-title">工作时间</div>
          <div class="footer-section__row-content">9:00 - 21:00</div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-title">服务热线</div>
          <div class="footer-section__row-content">400-066-0526</div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-title">企业邮箱</div>
          <div class="footer-section__row-content">
            <a
              class="footer-link"
              target="_blank"
              href=""
              >liyongsheng@yunlankeji.com</a
            >
          </div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-title">投诉通道</div>
          <div class="footer-section__row-content">
            <a class="footer-link" href=""
              >liyongsheng@yunlankeji.com</a
            >
          </div>
        </div>
      </div>
      <div class="footer-section footer-section_02">
        <div class="footer-section__title">拨打专线更快响应哦</div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            渠道合作&#x3000;400-066-0526
          </div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            市场推广&#x3000;400-066-0526
          </div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            连锁客户&#x3000;400-066-0526
          </div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            廉洁合规&#x3000;400-066-0526
          </div>
        </div>
      </div>

      <div class="footer-section footer-section_03">
        <div class="footer-section__title">资质证照</div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            <a
              class="footer-link"
              href=""
              target="_blink"
              style="color:#9b9b9b"
              ><router-link to="/businessLience" style="color:#9b9b9b">工商营业执照</router-link></a
            >
          </div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            <a
              class="footer-link"
              href=""
              target="_blink"
              style="color:#9b9b9b"
              ><router-link to="/increment" style="color:#9b9b9b">增值电信业务经营许可证</router-link></a
            >
          </div>
        </div>
      </div>
      
      <div class="footer-section footer-section_04">
        <div class="footer-section__title">友情链接</div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            <a
              class="footer-link"
              href="https://www.yunlankeji.com/"
              target="_blink"
              >云蓝科技</a
            >
          </div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            <a
              class="footer-link"
              href="http://xy.yunlankeji.com/"
              target="_blink"
              >百鸣</a
            >
          </div>
        </div>
      </div>

      <div class="footer-section footer-section_05">
        <div class="footer-section__title">
          <img
            src="../assets/images/common/wechat.png"
            class="footer-section__title-icon"
          />
          微信咨询
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-content">
            <img
              style="width: 100px"
              src="../assets/images/common/code.png"
              alt="wx"
            />
          </div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-title">微信扫一扫或者</div>
        </div>
        <div class="footer-section__row">
          <div class="footer-section__row-title">搜索“店教授收银”</div>
        </div>
      </div>

      <div
        style="
          position: absolute;
          top: 390px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: #383536;
        "
      ></div>

      <div class="footer-bottom color-white">
        <div class="text-1">
          <!-- <a href="">版本更新</a>
          |
          <a href="" target="_blank">支持社区</a>
          | -->
          <a href=""><router-link to="/cooperation">渠道合作</router-link></a>
          |
          <a href=""><router-link to="/serviceAgreement">服务协议</router-link></a>
          |
          <a href=""><router-link to="/compliance">廉洁合规</router-link></a>
          |
          <a href=""><router-link to="/about">关于我们</router-link></a>
          |
          <a href=""><router-link to="/contact">联系我们</router-link></a>
        </div>
        <div class="text-2">
          2022 © Copyright. All Rights Reserved
          <br />
          安徽云蓝信息科技有限公司版权所有
          <!-- <a href="" target="_blank"
            >皖ICP备18013121号-3</a
          > -->
          <br />
          <!--<a class="color-gray" style="text-decoration: underline !important; text-decoration-color: #ffffff;" href="http://www.beianbeian.com/beianxinxi/172df235-ddc9-4a65-b38e-602d7ba3b775.html" target="_blank">闽ICP备10208378号</a>-->
          <div style="width: 300px; margin: 0 auto; padding: 5px 0">
            <a
              target="_blank"
              href="https://beian.miit.gov.cn/#/Integrated/index"
              style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              "
            >
              <img
                src="../assets/images/common/beian.png"
                style="float: left"
              />
              <p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: #939393;
                "
              >
                皖ICP备18013121号-3
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer {
  margin-top: -23px;
  height: 544px;
  background-color: #191a19;
}

.footer-content {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.footer-section {
  position: absolute;
  top: 134px;
}
.footer-section_01 {
  left: 0;
  padding-left: 42px;
}
.footer-section_02 {
  left: 412px;
}
.footer-section_03 {
  left: 666px;
}
.footer-section_04 {
  left: 900px;
}
.footer-section_05 {
  right: 0;
}

.footer-section__title {
  font-size: 14px;
  line-height: 20px;
  color: #ebebeb;
  margin-bottom: 20px;
}
.footer-section_04 .footer-section__title {
  margin-bottom: 12px;
}
.footer-section__title-icon {
  height: 28px;
  vertical-align: -10px;
}

.footer-section_01 .footer-section__row {
  margin-bottom: 12px;
}
.footer-section_02 .footer-section__row {
  margin-bottom: 5px;
}
.footer-section_03 .footer-section__row {
  margin-bottom: 5px;
}
.footer-section_04 .footer-section__row {
  margin-bottom: 5px;
}

.footer-section__row-title {
  font-size: 14px;
  line-height: 20px;
  color: #ebebeb;
}
.footer-section_01 .footer-section__row-title {
  display: inline-block;
  width: 110px;
}

.footer-section__row-content {
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
}
.footer-section_01 .footer-section__row-content {
  display: inline-block;
}

.footer-link {
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
}
.footer-link:hover {
  color: #ebebeb;
}

.footer .footer-bottom {
  position: absolute;
  width: 100%;
  top: 420px;
  text-align: center;
}

.footer .footer-bottom .text-1 {
  color: #9f9f9f;
  font-size: 14px;
}

.footer .footer-bottom .text-1 a {
  color: #9f9f9f;
  margin: 0 10px;
}

.footer .footer-bottom .text-1 a:hover {
  color: #fff;
}

.footer .footer-bottom .text-2 a {
  color: #707070;
}

.footer .footer-bottom .text-2 a:hover {
  color: #fff;
}

.footer .footer-bottom .text-2 {
  color: #707070;
  font-size: 13px;
  margin: 10px 0 0 0;
}
</style>