<template>
  <div id="app" :style="isShow == true ? 'padding-top: 70px;':''">
    <!-- 页头 -->
    <header-view v-if="isShow == true"></header-view>
    <!-- 路由占位符 -->
    <router-view ></router-view>
    <!-- 页尾 -->
     <footer-view v-if="isShow == true"></footer-view>
  </div>
</template>

<script>
import header from '@/components/header';
import footer from '@/components/footer';
export default {
  components:{
     "header-view":header,
     "footer-view":footer
  },
  data() {
    return {
      isShow: true
    }
  },
  methods: {
  },
  created() {
     // 隐藏loading事件
    document.getElementById("loading").style.display = "none";
  },
  watch: {
    $route: {
      handler: function (route) {
        var that = this;
        if (
          route.path == "/businessLience" ||
          route.path == "/increment"
        ) {
          that.isShow = false;
        } else {
          that.isShow = true;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
#app{
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  /* padding-top: 70px; */
}
</style>
